/* Global styles */
html,
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #f4f4f4;
}
/* Header styles */
header {
  width: 100%;
  background-color: #77ba7a; /* Green background */
  color: white; /* White text */
  padding: 20px;
  box-sizing: border-box;
  position: relative; /* Ensure header is on top */
  z-index: 10;
  /* Remove text-align: center; since we'll handle alignment with flexbox */
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the h1 horizontally */
  position: relative; /* For positioning the logo absolutely within this container */
}

.header-container .logo {
  position: absolute;
  left: 20px; /* Adjust spacing from the left edge */
  width: 200px; /* Adjust the size of your logo */
  height: auto;
}

.header-container h1 {
  margin: 0;
  font-size: 2em;
}

/* Button styles */
button,
.FileUploadBox button {
  border: none;
  color: white;
  padding: 10px 24px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

button {
  background-color: #008CBA; /* Blue background */
  margin: 10px;
  text-align: center;
}

.FileUploadBox button {
  background-color: #77ba7a; /* Green background */
}

button:disabled,
.FileUploadBox button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

button:hover:not(:disabled) {
  background-color: #007bb5;
}

.FileUploadBox button:hover:not(:disabled) {
  background-color: #77ba7a;
}

/* Container styles */
.Example {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 0; /* Remove padding to prevent overflow */
}

.Example__container {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: 20px;
}

/* FileUploadBox styles */
.FileUploadBox {
  width: 300px; /* Adjusted width */
  flex-shrink: 0; /* Prevent shrinking */
  margin: 20px; /* Add margin for spacing */
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-sizing: border-box; /* Ensure padding is included in width */
}

/* Main content area (PDF viewer and RHS panel) */
.main-content {
  display: flex;
    justify-content: flex-start; /* Add this to align items to the start */
  flex: 1; /* Take up remaining space */
    gap: 20px; /* Add space between items */
  min-width: 0;
}

/* Document viewer area */
.Example__document-viewer {
  position: relative;
  margin: 20px; /* Add margin for spacing */
  overflow: auto; /* Allow scrolling if content overflows */
  box-sizing: border-box;
  min-width: 0;
}

/* Right-side panel */
.right-side-panel {
  padding: 20px;
  background-color: #f5f5f5;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: scroll;
  max-width: 50%
}

/* PDF Viewer styles */
.pdf-viewer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
}

.pdf-viewer .react-pdf__Page {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.rectangle-container {
  position: absolute;
  cursor: pointer;
}

.rectangle-container:hover,
.rectangle-container:focus {
  border: 2px solid rgba(0, 0, 0, 0.5);
}

.rectangle-container:focus {
  outline: none;
}

/* Controls styles */
.controls {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

/* Selected file name styles */
.selected-file-name {
  font-size: 14px;
  color: #555;
  margin-bottom: 15px;
  word-break: break-word; /* Break long file names if necessary */
}

/* Media query for responsive design */
@media (max-width: 768px) {
  .Example__container {
    flex-direction: column; /* Stack items vertically */
  }

  .FileUploadBox,
  .main-content,
  .Example__document-viewer,
  .right-side-panel {
    width: 100%;
    margin: 10px 0;
    max-width: none; /* Remove max-width to allow full width */
    flex: 0 0 auto; /* Allow items to take their natural size */
  }

  .main-content {
    flex-direction: column; /* Stack PDF Viewer and RHS panel */
    flex: 1; /* Take up remaining space */
  }
}

/* Typography and content styles */
.right-side-panel h3,
.info-title {
  font-size: 1.5em; /* Adjust the size as needed */
}

.right-side-panel p,
.tooltip-text,
.tooltip-content {
  font-size: 1.1em; /* Adjust the size as needed */
  line-height: 1.5;
}

/* Tooltip and table styles */
.tooltip-content table,
.tooltip-content td,
.tooltip-content th {
  border: 1px solid black;
  border-collapse: collapse; /* Ensure borders don't double up */
}

.tooltip-content td,
.tooltip-content th {
  padding: 5px; /* Optional: Add some padding */
}

/* Info panel styles */
.info-panel {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  /* Optional: Add a subtle shadow */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.info-title {
  margin-bottom: 15px;
  /* Optional: Add a bottom border */
  border-bottom: 2px solid #f0f0f0;
  padding-bottom: 10px;
}
